<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    components: {
        DatePicker
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                name: '',
                amount: '',
                discount: '',
                tax_folio: '',
                biill_type_id: '',
                company_id: '',
                description: '',
                file: '',
                xml: '',
                user_id: '',
                files: [],
                billing_data_id: '',
                bank_account_providers_id: '',
                subtotal: '',
                iva: '',
                isr_ret: '',
                iva_ret: '',
                date: '',
                currency_id: 1,
                folio: '',
                ieps: '',

                generate_order: '',
                check_type_id: '',
                check_budget_id: '',
                check_buy_id: '',
                check_building_id: '',
                check_indiviso_id: '',
                check_concept_id: '',
                check_bill_id: '',
                check_type_payment_id: '',
                exchange: '',

                change_date: '',
                exchange_rate: '',
                amount_usd: '',
            },
            file: '',
            fileName: '',
            xml: '',
            xmlName: '',
            files: [],
            send: false,
            load: false,

            block: false,
            petty_cash: ''
        }
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.name = '';
            this.form.description = '';
            this.form.file = '';
            this.form.amount = '';
            this.form.xml = '';
            this.form.tax_folio = '';
            this.form.bank_account_providers_id = '';
            this.form.subtotal = '';
            this.form.discount = '';
            this.form.iva = '';
            this.form.date = '';
            this.form.folio = '';
            this.form.exchange = '';
            this.form.currency_id = 1;
            if (this.authUser.organization_charts_id == 2) {
                this.form.user_id = this.authUser.id;
            } else {
                this.form.user_id = '';
            }
            this.form.billing_data_id = '';
            this.form.biill_type_id = '';
            this.form.company_id = '';
            this.form.files = [];
            this.fileName = '';
            this.xmlName = '';
            this.file = '';
            this.xml = '';
            this.block = false;
            this.files = [];
            this.send = false;
            this.errors = {};
            this.form.iva_ret = '';
            this.form.isr_ret = '';
            this.form.ieps = '';

            this.form.generate_order = '';
            this.form.check_type_id = '';
            this.form.check_budget_id = '';
            this.form.check_buy_id = '';
            this.form.check_building_id = '';
            this.form.check_indiviso_id = '';
            this.form.check_company_id = '';
            this.form.check_concept_id = '';
            this.form.check_bill_id = '';
            this.form.check_type_payment_id = '';

            this.form.change_date= '';
            this.form.exchange_rate= '';
            this.form.amount_usd= '';

            this.$modal.hide('addBill');
        },
        uploadFilesBill(event) {
            let vm = this;

            let file = event.target.files[0];
            // let allowedImageTypes = ["image/jpeg", "image/gif", "image/png"];
            // if (!allowedImageTypes.includes(file.type)) {
            //     this.$swal({
            //         icon: 'error',
            //         title: 'Error en el tipo de archivo',
            //         text: 'Solo se acepta archivo tipo imagen',
            //     });
            // } else {
            vm.form.files.push(file);
            console.log(vm.form.files);
            this.$forceUpdate();

            // }
        },
        createURL(file) {
            let link = URL.createObjectURL(file);
            return link;
        },
        calcTotal() {
            this.form.amount = this.form.subtotal + this.form.iva + this.form.ieps - this.form.isr_ret - this.form.iva_ret - this.form.discount;
        },
        calcTotalUSD() {
            this.form.subtotal = this.form.exchange_rate * this.form.amount_usd;
        },
        async delImgUpload(pos) {
            let img = this.files[pos];
            let id = img.id;
            let _endpoint = '/bills/deleted-documents';
            let form = {
                id: id
            };
            let { data } = await request.postMedia(_endpoint, form)
            if (data) {
                this.successNotification(data.message);
                this.files.splice(pos, 1);
            }

        },
        delImg(pos) {
            this.form.files.splice(pos, 1);
            this.successNotification('El archivo se elimino correctamente.');
        },
        catch_file(event) {
            if (event.target.files[0].type == "application/pdf") {
                this.form.file = event.target.files[0];
                this.fileName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF',
                });
            }
        },
        catch_xml(event) {
            console.log(event.target.files[0].type);
            if (event.target.files[0].type == "text/xml") {
                this.form.xml = event.target.files[0];
                this.xmlName = event.target.files[0].name;
                this.catchDataXML(event.target.files[0]);


            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos XML',
                });
            }
        },
        async catchDataXML(file) {
            console.log(file);
            const data_form = new FormData();
            data_form.append('xml', file);

            let _endpoint = `/bill_load_xml`;

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    // if (data.in_month == true) {
                    this.form.folio = data.Folio;
                    this.form.tax_folio = data.UUID;
                    this.form.subtotal = data.SubTotal;
                    this.form.iva = data.iva;
                    this.form.iva_ret = data.iva_ret;
                    this.form.discount = data.Descuento;
                    this.form.ieps = data.ieps;
                    this.form.isr_ret = data.isr_ret
                    if (data.currency_id) {
                        this.form.currency_id = data.currency_id;
                    }
                    if (data.bill_types_id) {
                        this.form.biill_type_id = data.bill_types_id;
                    }
                    if (data.company_id) {
                        this.form.company_id = data.company_id;
                    }
                    let DateBill = this.moment(data.Fecha).format('YYYY-MM-DD');
                    this.form.date = DateBill;
                    if (this.authUser.organization_charts_id != 2) {
                        if (data.user_id) {
                            this.form.user_id = data.user_id;
                        }
                    }

                    if (data.billing_data_id) {
                        this.form.billing_data_id = data.billing_data_id;
                    }

                    if (data.impuestos) {
                        this.form.billing_data_id = data.impuestos;
                    }
                    this.block = true;
                    // } else {
                    //     this.form.xml = '';
                    //     this.xmlName = '';
                    //     this.$swal({
                    //         icon: 'error',
                    //         title: 'No se puede cargar la factura',
                    //         text: 'No se puede cargar porque la fecha de emisión es de otro mes'
                    //     });
                    // }
                }
            } catch (error) {
                this.send = false;
                this.errors = {};
                if (error.response) {

                    this.errors = await this.errorNotification(error);
                }
            }

        },
        async saveBuildings() {
            this.send = true;
            const data_form = new FormData();
            data_form.append('id', this.form.id);
            data_form.append('name', this.form.name);
            data_form.append('description', this.form.description);
            data_form.append('file', this.form.file);
            data_form.append('amount', this.form.amount);
            data_form.append('xml', this.form.xml);
            data_form.append('user_id', this.form.user_id);
            data_form.append('company_id', this.form.company_id);
            data_form.append('biill_type_id', this.form.biill_type_id);
            data_form.append('tax_folio', this.form.tax_folio  ? this.form.tax_folio : '');
            data_form.append('billing_data_id', this.form.billing_data_id);
            data_form.append('bank_account_providers_id', this.form.bank_account_providers_id);
            data_form.append('subtotal', this.form.subtotal);
            data_form.append('discount', this.form.discount);
            data_form.append('iva', this.form.iva);
            data_form.append('isr_ret', this.form.isr_ret);
            data_form.append('iva_ret', this.form.iva_ret);
            data_form.append('date', this.form.date);
            data_form.append('currency_id', this.form.currency_id);
            data_form.append('folio', this.form.folio);
            data_form.append('ieps', this.form.ieps);

            data_form.append('generate_order', this.form.generate_order);
            data_form.append('check_type_id', this.form.check_type_id);
            data_form.append('check_budget_id', this.form.check_budget_id);
            data_form.append('check_buy_id', this.form.check_buy_id);
            data_form.append('check_building_id', this.form.check_building_id);
            data_form.append('check_indiviso_id', this.form.check_indiviso_id);
            data_form.append('check_company_id', this.form.check_company_id);
            data_form.append('check_concept_id', this.form.check_concept_id);
            data_form.append('check_bill_id', this.form.check_bill_id);
            data_form.append('check_type_payment_id', this.form.check_type_payment_id);
            data_form.append('exchange', this.form.exchange);

            data_form.append('change_date',this.form.change_date);
            data_form.append('exchange_rate',this.form.exchange_rate);
            data_form.append('amount_usd',this.form.amount_usd);

            this.form.files.forEach(function (valor, index) {
                data_form.append('files[' + index + ']', valor);
            });


            let _endpoint = '/bills';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/bills/${this.form.id}`;
            }
            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-bills');
                    Event.$emit('event-load-petty-cash-bill', this.petty_cash, data.id);
                }
            } catch (error) {
                this.send = false;
                this.errors = {};
                if (error.response) {

                    this.errors = await this.errorNotification(error);
                }
            }
        },
        // Check
        getBuysBudgets(user_id) {
            console.log('paso0');
            console.log(user_id);
            console.log(this.form.concept_id);

            if (user_id && this.form.check_type_id) {
                console.log('paso');
                let company_id = this.form.company_id;
                this.catalogue.get_budgets_user(user_id, this.form.check_concept_id, company_id);
                this.catalogue.get_buys_user(user_id, this.form.check_concept_id, company_id);
            }
        },
        getDataBudget() {
            let budgets = this.catalogue.budgets_user;
            console.log(budgets);

            let budget_id = this.form.check_budget_id;
            let res = budgets.find((s) => s.id === budget_id);
            console.log(res.concept_id);
            this.form.check_concept_id = res.concept_id;
        },
        getDataBuy() {
            let buys = this.catalogue.buys_user;
            console.log(buys);
            let buy_id = this.form.check_buy_id;
            console.log(buy_id);
            let res = buys.find((s) => s.id === buy_id);
            console.log(res);
            this.form.check_concept_id = res.concept_id;
        },
        //check

        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    watch: {
        'form.user_id'(value) {
            this.company_id = '';
            this.catalogue.get_companies_users(this.form.user_id);
            this.catalogue.getBillingDataUser(value);
            this.catalogue.get_bills_user(this.form.user_id, this.form.id);
        },
        'form.check_type_id'() {
            // this.form.budget_id = '';
            // this.form.buy_id = '';

            this.getBuysBudgets(this.form.user_id);
        },
        'form.check_budget_id'() {
            this.getDataBudget();
        },
        'form.check_buy_id'() {
            this.getDataBuy();
        },
        'form.name'(value) {
            let str = value;
            let oldText = str.slice(0, str.length - 1);

            if (this.form.id == '' && oldText == this.form.description) {
                this.form.description = value;
            }
        },
        'form.billing_data_id'(value) {
            if (!this.load) {
                this.form.bank_account_providers_id = '';
            }

            this.catalogue.getBankBillAccountProvider(value);

            if (this.load) {
                this.load = false;
            }

        },
        'catalogue.bank_bill_account_provider'(value) {
            console.log(value);
            console.log(value.length);
            if (value.length > 0) {
                let bank_account_providers_id = this.form.bank_account_providers_id;

                let existe = value.filter(function (account) {
                    return account.id === bank_account_providers_id ? true : false;
                });

                if (existe.length == 0) {
                    this.form.bank_account_providers_id = value[0].id;
                }
            }
        },
        async 'form.check_building_id'(value) {
            console.log(value);

            await this.catalogue.get_departamens_buildings(value);
            await this.catalogue.get_companies_buildings(value);

            let stor = localStorage.getItem('bill_data');
            console.log(stor);


        },
        'form.subtotal'() {
            this.calcTotal();
        },
        'form.discount'() {
            this.calcTotal();
        },
        'form.iva'() {
            this.calcTotal();
        },
        'form.isr_ret'() {
            this.calcTotal();
        },
        'form.iva_ret'() {
            this.calcTotal();
        },
        'form.ieps'() {
            this.calcTotal();
        }
    },
    mounted() {
        this.catalogue.get_buildings();
        this.catalogue.get_users_profile(2);
        this.catalogue.get_bills_types();
        this.catalogue.getCurrencies();
        this.catalogue.get_concepts();
        this.catalogue.get_type_payments();

        if (this.authUser.organization_charts_id == 2) {
            this.form.user_id = this.authUser.id;
            this.catalogue.get_companies_users(this.authUser.id);
            this.catalogue.getBillingDataUser(this.authUser.id);
            //this.catalogue.getBankBillAccountProvider(this.authUser.id);

        }
    },
    created() {
        Event.$on("event-show-addBill", async (account) => {
            console.log(account);
            if (account.id) {
                this.load = true;
                this.form.id = account.id;
                this.form.name = account.name;
                this.form.description = account.description;
                this.form.user_id = account.user_id;
                this.form.amount = account.amount;
                this.form.biill_type_id = account.biill_type_id;
                this.form.company_id = account.company_id;
                this.form.tax_folio = account.tax_folio ? account.tax_folio : '' ;
                this.form.billing_data_id = account.billing_data_id;
                this.form.bank_account_providers_id = account.bank_account_providers_id;
                this.form.subtotal = account.subtotal;
                this.form.iva = account.iva;
                this.form.iva_ret = account.iva_ret;
                this.form.isr_ret = account.isr_ret;
                this.form.date = account.date;
                this.form.currency_id = account.currency_id;
                this.form.folio = account.folio;
                this.form.discount = account.discount;
                this.form.ieps = account.ieps;

                this.form.change_date= account.change_date;
                this.form.exchange_rate= account.exchange_rate;
                this.form.amount_usd= account.amount_usd;

                if (account.file) {
                    this.file = account.file;
                }
                if (account.xml) {
                    this.xml = account.xml;
                }
                if (account.files) {
                    this.files = account.files;
                }
            }
            this.$modal.show('addBill');
        });
        Event.$on("event-show-addBillPettyCash", async(petty_cash) => {
            console.log('petty_cash');
            console.log(petty_cash);
            this.petty_cash = petty_cash.id;
            this.form.name = "caja chica #"+petty_cash.id;
            this.form.description = "caja chica #"+petty_cash.id;
            this.form.company_id = petty_cash.company_id;
            this.form.biill_type_id = petty_cash.type_id;
            this.form.subtotal = petty_cash.amount;
            this.$modal.show('addBill');
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
}
</script>
<template>
    <modal name="addBill" :width="'80%'" :maxHeight="300" :height="'auto'" v-bind:click-to-close="false">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                    class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información Factura</h5>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="col-md-12 mb-3">
                        <div class="row justify-content-start">
                            <div class="col-md-4" :style="form.currency_id == 2 ? 'padding-top: 30px' : ''">
                                <label v-for="(currency, index) in catalogue.currencies" :key="index" class="ml-1">
                                    {{ currency.name }} <input :disabled="!prop_edit" v-model="form.currency_id"
                                        :value="currency.id" type="radio" name="currency">
                                </label>
                            </div>

                            <!-- USD -->                          
                            <div class="col-md-4" v-if="form.currency_id == 2">
                                <label> Fecha tipo de cambio </label>
                                <date-picker v-model="form.change_date" :disabled="!prop_edit"
                                    valueType="format"></date-picker>
                                <div v-if="errors && errors.change_date" class="text-danger">{{ errors.change_date[0] }}</div>
                            </div>
                            <div class="col-md-4" v-if="form.currency_id == 2">
                                <label> Tipo de cambio </label>
                                <money :disabled="!prop_edit" class="form-control" v-model="form.exchange_rate"></money>
                                <div v-if="errors && errors.exchange_rate" class="text-danger">{{ errors.exchange_rate[0] }}</div>
                            </div>
                            <!-- <div class="col-md-3" v-if="form.currency_id == 2">
                                <label> Monto USD </label>
                                <money :disabled="!prop_edit" :keyup="calcTotalUSD()" class="form-control" v-model="form.amount_usd"></money>
                                <div v-if="errors && errors.amount_usd" class="text-danger">{{ errors.amount_usd[0] }}</div>
                            </div>                           -->

                           
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label>PDF</label><br>
                        <div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
                            <input :disabled="!prop_edit" type="file" class="custom-file-input"
                                :class="file != '' ? 'pr-5' : ''" id="customFile" ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>
                    <div class="col-md-6">
                        <label>XML</label><br>
                        <div class="custom-file" :style="xml != '' ? 'width: 87%' : 'width: 100%'">
                            <input :disabled="!prop_edit" type="file" id="fileXML" class="custom-file-input"
                                :class="xml != '' ? 'pr-5' : ''" ref="file" @change="catch_xml">
                            <label class="custom-file-label text-left" for="customFile">{{ xmlName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${xml}`" v-if="xml != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.xml" class="text-danger">{{ errors.xml[0] }}</div>
                    </div>

                    <div class="col-md-12" v-if="authUser.organization_charts_id != 2">
                        <label>Usuario</label>
                        <select :disabled="!prop_edit" type="text" class="form-control" v-model="form.user_id">
                            <option v-for="(user, index) in catalogue.users_profile" :value="user.id" :key="index">{{
        user.name }} {{ user.last_name }}</option>
                        </select>
                        <div v-if="errors && errors.user_id" class="text-danger">{{ errors.user_id[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Datos de Facturación</label>
                        <select :disabled="!prop_edit" type="text" class="form-control" v-model="form.billing_data_id">
                            <option value="">Sin datos de facturación</option>
                            <option v-for="(billD, index) in catalogue.billing_data_user" :value="billD.id"
                                :key="index">{{
        billD.business_name }} - {{ billD.rfc }}</option>
                        </select>
                        <div v-if="errors && errors.user_id" class="text-danger">{{ errors.user_id[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Cuenta Bancaria</label>
                        <select :disabled="!prop_edit" type="text" class="form-control"
                            v-model="form.bank_account_providers_id">
                            <option value="">Sin cuenta bancaria</option>
                            <option v-for="(bank, index) in catalogue.bank_bill_account_provider" :value="bank.id"
                                :key="index">
                                {{
        bank.bank.name }} - {{ bank.account_number }}</option>
                        </select>
                        <div v-if="errors && errors.bank_account_providers_id" class="text-danger">{{
        errors.bank_account_providers_id[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>Concepto</label>
                        <input :disabled="!prop_edit" type="text" class="form-control" v-model="form.name">
                        <div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>Folio Fiscal</label>
                        <input :disabled="!prop_edit || authUser.organization_charts_id == 2 || block" type="text"
                            class="form-control" v-model="form.tax_folio">
                        <div v-if="errors && errors.tax_folio" class="text-danger">{{ errors.tax_folio[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>Folio</label>
                        <input :disabled="!prop_edit" type="text" class="form-control" v-model="form.folio">
                        <div v-if="errors && errors.folio" class="text-danger">{{ errors.folio[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>Fecha de emisión</label>
                        <date-picker v-model="form.date" :disabled="!prop_edit" required="required"
                            valueType="format"></date-picker>
                        <div v-if="errors && errors.date" class="text-danger">{{ errors.date[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>Subtotal</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.subtotal"></money>
                        <div v-if="errors && errors.subtotal" class="text-danger">{{ errors.subtotal[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>IVA</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.iva"></money>
                        <div v-if="errors && errors.iva" class="text-danger">{{ errors.iva[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>IEPS</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.ieps"></money>
                        <div v-if="errors && errors.ieps" class="text-danger">{{ errors.ieps[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>ISR RET</label>
                        <money class="form-control" :disabled="!prop_edit" v-model="form.isr_ret"></money>
                        <div v-if="errors && errors.isr_ret" class="text-danger">{{ errors.isr_ret[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>IVA RET</label>
                        <money class="form-control" :disabled="!prop_edit" v-model="form.iva_ret"></money>
                        <div v-if="errors && errors.iva_ret" class="text-danger">{{ errors.iva_ret[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>Descuento</label>
                        <money class="form-control" :disabled="!prop_edit" v-model="form.discount"></money>
                        <div v-if="errors && errors.discount" class="text-danger">{{ errors.discount[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Total</label>
                        <money readonly class="form-control" v-model="form.amount"></money>
                        <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Tipo</label>
                        <select :disabled="!prop_edit" type="text" class="form-control" v-model="form.biill_type_id">
                            <option v-for="(type, index) in catalogue.bills_types" :value="type.id" :key="index">{{
        type.name }}</option>
                        </select>
                        <div v-if="errors && errors.biill_type_id" class="text-danger">{{ errors.biill_type_id[0] }}
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Empresa</label>
                        <select :disabled="!prop_edit" type="text" class="form-control" v-model="form.company_id">
                            <option v-for="(company, index) in catalogue.companies_users" :value="company.id"
                                :key="index">
                                {{
        company.name }}</option>
                        </select>
                        <span class="text-info text-sm"
                            v-if="catalogue.companies_users.length == 0 && form.user_id != ''">No tienes empresas
                            relacionadas</span>
                        <div v-if="errors && errors.company_id" class="text-danger">{{ errors.company_id[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Descripción</label>
                        <textarea :disabled="!prop_edit" type="text" class="form-control"
                            v-model="form.description"></textarea>
                        <div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}</div>
                    </div>




                    <div class="col-12">
                        <hr>
                        <div class="row">
                            <div class="col-12 text-center">
                                <h3>Archivo adjuntos.</h3>
                                <p>Máximo 15 archivos.</p>
                                <div class="row mt-4 mb-4">
                                    <div class="col-12" v-if="(form.files.length + files.length) == 0">
                                        <i class="fa-solid fa-file fa-4x"></i>
                                        <p>Sin archivos adjuntos</p>
                                    </div>
                                    <div class="col-md-4 col-12 p-2" v-for="(fAdd, index_fAdd) in files"
                                        :key="index_fAdd" v-show="files.length > 0">
                                        <div class="card shadow">
                                            <button v-if="prop_edit" class="btn btn-sm btn-danger del" type="button"
                                                @click="delImgUpload(index_fAdd)">
                                                <i class="fa fa-trash"></i></button>
                                            <div class="card-body text-center">
                                                <a :href="`${globalUrlFiles}${fAdd.file}`" target="_blank">
                                                    <i class="fa fa-file fa-4x"></i>
                                                    <p>{{ fAdd.name }}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4 col-12 p-2" v-for="(img, index_img) in form.files"
                                        :key="index_img" v-show="form.files.length > 0">
                                        <div class="card shadow">
                                            <button v-if="prop_edit" class="btn btn-sm btn-danger del" type="button"
                                                @click="delImg(index_img)"><i class="fa fa-trash"></i></button>
                                            <div class="card-body text-center">
                                                <a :href="createURL(img)" target="_blank">
                                                    <i class="fa fa-file fa-4x"></i>
                                                    <p>{{ img.name }}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="input-group mb-3"
                                    v-if="(form.files.length + files.length) <= 14 && prop_edit">
                                    <div class="custom-file" @change="uploadFilesBill">
                                        <input type="file" class="custom-file-input" id="inputGroupFile03"
                                            aria-describedby="inputGroupFileAddon03">
                                        <label class="custom-file-label" for="inputGroupFile03">
                                            Subir Archivo
                                        </label>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                    <div class="col-md-12"
                        v-if="form.id == '' && (authUser.organization_charts_id == 1 || authUser.organization_charts_id == 3)">
                        <hr>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <label>
                                    <input type="checkbox" class="form-control form-control-sm"
                                        v-model="form.generate_order">
                                    Generar orden de pago
                                </label>
                            </div>
                            <div class="col-md-12" v-if="form.generate_order">
                                <div class="row">
                                    <div class="mt-3 col-md-12">
                                        <label>Tipo</label>
                                        <select type="text" :disabled="!prop_edit" class="form-control"
                                            v-model="form.check_type_id">
                                            <option :value="1">Presupuestos</option>
                                            <option :value="2">Gastos recurrente</option>
                                            <option :value="3">Compra unica</option>
                                        </select>
                                        <div v-if="errors && errors.check_type_id" class="text-danger">
                                            {{ errors.check_type_id[0] }}
                                        </div>
                                    </div>


                                    <div class="col-md-8 mt-3" v-if="form.check_type_id == 1">
                                        <label>Presupuesto</label>
                                        <div class="input-group mb-3 text-center" v-if="catalogue.budgets_user_load">
                                            <p style="width: 100%;">
                                                <i class="fa-solid fa-circle-notch fa-spin fa-lg"></i>
                                            </p>
                                        </div>
                                        <div class="input-group mb-3" v-else>
                                            <select type="text" :disabled="!prop_edit" required class="form-control"
                                                v-model="form.check_budget_id">
                                                <option v-for="(budget, index_budeget) in catalogue.budgets_user"
                                                    :value="budget.id" :key="index_budeget">
                                                    {{ budget.folio }},
                                                    {{ budget.building.name }},
                                                    $ {{ budget.amount }}
                                                </option>
                                                <option value="" v-if="catalogue.budgets_user.length == 0"> Sin
                                                    resultados</option>
                                            </select>
                                        </div>
                                        <div class="text-sm text-info"
                                            v-if="form.check_user_id && catalogue.budgets_user.length == 0">Sin
                                            presupuesto, necesita ingresar uno a este usuario</div>
                                        <div v-if="errors && errors.budget_id" class="text-danger">{{
        errors.budget_id[0] }}</div>
                                    </div>

                                    <div class="col-md-8 mt-3" v-if="form.check_type_id == 2">
                                        <label>Gasto recurrente</label>
                                        <div class="input-group mb-3 text-center" v-if="catalogue.buys_user_load">
                                            <p style="width: 100%;">
                                                <i class="fa-solid fa-circle-notch fa-spin fa-lg"></i>
                                            </p>
                                        </div>
                                        <div class="input-group mb-3" v-else>
                                            <select type="text" :disabled="!prop_edit" required class="form-control"
                                                v-model="form.check_buy_id">
                                                <option v-for="(buy, index_buy) in catalogue.buys_user" :value="buy.id"
                                                    :key="index_buy">
                                                    {{ buy.building.name }},
                                                    {{ buy.payment_period.name }}
                                                    {{ buy.amount }} - {{ buy.star_date | toDateTime }}
                                                </option>
                                                <option value="" v-if="catalogue.buys_user.length == 0"> Sin resultados
                                                </option>
                                            </select>

                                        </div>
                                        <div class="text-sm text-info"
                                            v-if="form.check_user_id && catalogue.buys_user.length == 0">Sin gasto
                                            recurrente,
                                            necesita ingresar uno a este usuario</div>
                                        <div v-if="errors && errors.buy_id" class="text-danger">{{ errors.buy_id[0] }}
                                        </div>
                                    </div>

                                    <!-- Pago unico -->
                                    <div class="col-md-6 mt-3" v-if="form.check_type_id == 3">
                                        <div class="form-group">
                                            <label> Propiedad </label>
                                            <select type="text" class="form-control" v-model="form.check_building_id">
                                                <option v-for="(building, index) in catalogue.buildings"
                                                    :value="building.id" :key="index">
                                                    {{ building.name }}</option>
                                            </select>
                                            <div v-if="errors && errors.check_building_id" class="text-danger">
                                                {{ errors.check_building_id[0] }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-3" v-if="form.check_type_id == 3">
                                        <div class="form-group">
                                            <label> Departamento </label>
                                            <select v-model="form.check_indiviso_id" class="form-control">
                                                <option value="">Sin departamento</option>
                                                <option v-for="(departament, index) in catalogue.departamens_buildings"
                                                    :key="index" :value="departament.id"> Número {{ departament.number
                                                    }} / Piso {{ departament.level
                                                    }}
                                                </option>
                                            </select>
                                            <div v-if="errors && errors.check_indiviso_id" class="text-danger">
                                                {{ errors.check_indiviso_id[0] }}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Pago unico -->

                                    <div v-if="form.check_type_id != ''" :class="form.check_type_id == 3 ? 'col-md-12 mt-2' : 'col-md-4 mt-3'">
                                        <label>Categoría</label>
                                        <select type="text" :disabled="!prop_edit" class="form-control"
                                            v-model="form.check_concept_id">
                                            <option v-for="(concept, index_concept) in catalogue.concepts"
                                                :value="concept.id" :key="index_concept">
                                                {{ concept.name }}</option>
                                        </select>
                                        <div v-if="errors && errors.check_concept_id" class="text-danger">
                                            {{ errors.check_concept_id[0] }}</div>
                                    </div>
                                    <!-- <div  :class="form.currency_id == 2 ? 'col-md-6 mt-3' : 'col-md-12 mt-3' ">
                                        <label>Concepto</label>
                                        <select type="text" :disabled="!prop_edit" class="form-control"
                                            v-model="form.check_bill_id">
                                            <option v-if="form.check_id == ''" value="">Crear Factura</option>
                                            <option v-for="(bill, index_bill) in catalogue.bills_user" :value="bill.id"
                                                :key="index_bill">
                                                {{bill.name }}
                                            </option>
                                            <option value=""
                                                v-if="catalogue.bills_user.length == 0 && form.check_id != ''">
                                                Sin resultados
                                            </option>
                                        </select>

                                        <div v-if="errors && errors.check_bill_id" class="text-danger">
                                            {{ errors.check_bill_id[0] }}
                                        </div>
                                    </div> -->

                                    <div v-if="form.currency_id == 2" :class="form.currency_id == 2 ? 'col-md-6 mt-3' : 'col-md-12 mt-3' ">
                                        <label>Tipo de cambio</label>
                                        <money :disabled="!prop_edit" class="form-control" v-model="form.exchange"></money>
                                        <div v-if="errors && errors.exchange" class="text-danger">{{ errors.exchange[0] }}</div>
                                    </div>
                                    <!--  -->


                                    <div class="col-md-12 mt-3">
                                        <label>Tipo de saldo</label>
                                        <select type="text" :disabled="!prop_edit" class="form-control"
                                            v-model="form.check_type_payment_id">
                                            <option
                                                v-for="(type_payment, index_type_payment) in catalogue.type_payments"
                                                :value="type_payment.id" :key="index_type_payment">{{
                                                type_payment.name }}</option>
                                        </select>
                                        <div v-if="errors && errors.check_type_payment_id" class="text-danger">{{
                                            errors.check_type_payment_id[0] }}
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" :disabled="send" type="submit"
                            class="btn float-right btn-outline-success">
                            <span v-if="send">
                                <i class="fa-solid fa-circle-notch fa-spin"></i> Guardando
                            </span>
                            <span v-else>
                                <i class="fas fa-save"></i> Guardar
                            </span>
                        </button>
                        <button type="button" :disabled="send" @click="closedModal()"
                            class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>
<style scoped>
.image-upload>input {
    display: none;
}

.del {
    position: absolute;
    right: 0px;
    margin: 10px;
}
</style>