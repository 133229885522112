<script>
import request from '@/services/request'
import { mapActions } from 'vuex';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },   
    data() {
        return {
            load: false,
            department_id: '',
            page: 1,
            errors: {},
            tenant: {
                total: 0
            },
            modify: false,
        }
    },
    methods: {
        closedModal() {          
            this.$modal.hide('ListPaysTenant');
        },       
        async list(page = 1) {
            this.load = true;
            let service = request;
            let search = {
                department_id: this.department_id,
                search: '',
                building_id: '',
                company_id: '',
                user_id: '',
                status: 1,
                finish: '',
                payment_period_id: [1, 2, 3, 4, 6, 7, 8, 9]
            };

            let _endpoint = '/tenant';
            try {
                let { data } = await service.list(_endpoint, page, search)
                if (data) {
                    console.log(data);
                    this.tenant = data;
                    this.load = false;
                }
            } catch (error) {
                console.log(error);
            }
        },        
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-ContractsDepartament", (departament) => {
            this.department_id = departament.id;
            this.list();
            this.$modal.show('ListPaysTenant');
        });      
    }
}
</script>
<template>
    <modal name="ListPaysTenant" :width="'80%'" :scrollable="true" :maxHeight="300" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Historial de contratos </h5>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">                   
                    <div class="table-responsive">
                        <table class="table table-bordered text-center text-sm">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Fechas</th>
                                    <th>Arrendatario</th>
                                    <th>Monto</th>
                                </tr>
                            </thead>
                            <tbody v-if="load">
                            <tr>
                                <td align="center" colspan="4">
                                    <i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
                                    <h5>Cargando</h5>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else-if="tenant.total > 0">
                                <tr v-for="(pay, index) in tenant.data" :key="index">
                                    <td>{{ pay.id }}</td>                                
                                    <td>
                                        {{ pay.star_date | toDateTime }} -
                                        {{ pay.end_date | toDateTime}}
                                    </td>   
                                    <td>
                                        <span v-if="pay.user">
                                            {{ pay.user.name }}
                                            {{ pay.user.last_name }}
                                        </span>
                                        <span v-else>
                                            N/A
                                        </span>                                    
                                    </td>   
                                    <td>
                                        {{pay.amount | toCurrency }}                                 
                                    </td>                              
                                </tr>                                                            
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td align="center" colspan="4">Sin pagos.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>