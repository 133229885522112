<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import ModalFormComponentBudgets from '@/components/budgets/ModalFormComponent.vue'
import ModalFormComponentBuy from '@/components/buy/ModalFormComponent.vue'


export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    components: {
        DatePicker,
        ModalFormComponentBudgets,
        ModalFormComponentBuy
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            single_purchase: 0,
            form: {
                id: '',
                type_id: 1,
                user_id: '',
                concept_id: '',
                budget_id: '',
                buy_id: '',
                bill_id: '',
                date: '',
                amount: '',
                amount_payable: '',
                type_payment_id: '',
                description: '',
                file: '',
                // pago unico
                company_id: '',
                indiviso_id: '',
                building_id: '',
                subtotal: '',
                iva: '',
                discount: '',
                isr_ret: '',
                iva_ret: '',
                change_date:'',
                ieps: 0,
                credit: 0,
                currency_id: 1,
            },
            file: '',
            fileName: ''
        }
    },
    methods: {
        closedModal() {
            this.single_purchase = 0;
            this.form.id = ''
            this.form.type_id = 1;
            this.form.user_id = '';
            this.form.concept_id = '';
            this.form.budget_id = '';
            this.form.buy_id = '';
            this.form.bill_id = '';
            this.form.date = '';
            this.form.amount = '';
            this.form.amount_payable = '';
            this.form.type_payment_id = '';
            this.form.description = '';
            this.form.subtotal = '';
            this.form.iva = '';
            this.form.discount = '';
            this.form.isr_ret = '';
            this.form.iva_ret = '';
            this.form.file = '';
            this.file = '';
            this.fileName = '';
            // pago unico
            this.form.company_id = '';
            this.form.indiviso_id = '';
            this.form.building_id = '';
            this.form.ieps = '';
            this.form.credit = '';
            this.form.currency_id = 1;
            this.form.change_date = '';

            this.$modal.hide('addChecks');
            localStorage.removeItem("bill_data");
        },
        addBudget() {
            let budget = {
                'user_id': this.form.user_id,
            };
            Event.$emit('event-show-addBudgets', budget);
        },
        addBuy() {
            let buy = {
                'user_id': this.form.user_id,
            };
            Event.$emit('event-show-addBuys', buy);
        },
        catch_file(event) {
            if (event.target.files[0].type == "application/pdf") {
                this.form.file = event.target.files[0];
                this.fileName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF',
                });
            }
        },
        async saveBuildings() {
            const data_form = new FormData();
            data_form.append('id', this.form.id)
            data_form.append('type_id', this.form.type_id)
            data_form.append('user_id', this.form.user_id)
            data_form.append('concept_id', this.form.concept_id)
            data_form.append('budget_id', this.form.budget_id)
            data_form.append('buy_id', this.form.buy_id)
            data_form.append('bill_id', this.form.bill_id)
            data_form.append('amount', this.form.amount)
            data_form.append('amount_payable', this.form.amount_payable)
            data_form.append('type_payment_id', this.form.type_payment_id)
            data_form.append('description', this.form.description)
            data_form.append('file', this.form.file)
            // pago unico
            data_form.append('company_id', this.form.company_id)
            data_form.append('indiviso_id', this.form.indiviso_id)
            data_form.append('building_id', this.form.building_id)

            data_form.append('subtotal', this.form.subtotal)
            data_form.append('iva', this.form.iva)
            data_form.append('isr_ret', this.form.isr_ret)
            data_form.append('iva_ret', this.form.iva_ret)
            data_form.append('discount', this.form.discount)
            data_form.append('ieps', this.form.ieps)
            data_form.append('credit', this.form.credit)
            data_form.append('currency_id', this.form.currency_id)
            data_form.append('exchange', this.form.exchange)
            data_form.append('change_date', this.form.change_date)

            if (this.form.date) {
                let dt = this.moment(this.form.date).format("YYYY-MM-DD");
                data_form.append('date', dt);
            }

            let _endpoint = '/checks';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/checks/${this.form.id}`;
            }
            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-checks');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        getDataBudget() {
            let budgets = this.catalogue.budgets_user;
            let budget_id = this.form.budget_id;
            let res = budgets.find((s) => s.id === budget_id);
            console.log(res.concept_id);
            this.form.concept_id = res.concept_id;
        },
        getDataBuy() {
            let buys = this.catalogue.buys_user;
            console.log(buys);
            let buy_id = this.form.buy_id;
            console.log(buy_id);
            let res = buys.find((s) => s.id === buy_id);
            console.log(res);
            this.form.concept_id = res.concept_id;
        },
        calcTotal() {
            this.form.amount = this.form.subtotal + this.form.iva + this.form.ieps - this.form.isr_ret - this.form.iva_ret - this.form.discount;
        },
        getBuysBudgets(user_id) {
            console.log('paso0');
            console.log(user_id);
            console.log(this.form.concept_id);

            if (user_id && this.form.type_id) {
                console.log('paso');
                let company_id = this.form.company_id;
                this.catalogue.get_budgets_user(user_id, this.form.concept_id, company_id);
                this.catalogue.get_buys_user(user_id, this.form.concept_id, company_id);
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    mounted() {
        this.catalogue.get_buildings();
        this.catalogue.get_users_profile(2);
        this.catalogue.get_concepts();
        this.catalogue.get_type_payments();
        let value = localStorage.getItem('bill_data');
        if (value) {
            let obj = JSON.parse(value);
            this.form.user_id = obj.idUser;
            this.form.bill_id = obj.idConvert;
            this.form.date = this.moment(obj.date).toDate();

            this.form.iva = obj.iva;
            this.form.isr_ret = obj.isr_ret;
            this.form.iva_ret = obj.iva_ret;
            this.form.subtotal = obj.subtotal;

            this.form.amount = obj.amount;
            this.form.amount_payable = obj.amount;
            this.form.company_id = obj.company_id;
            this.form.description = obj.description;
        }
    },
    created() {
        Event.$on("event-add-addChecksBill", async (bill) => {
            console.log('bill2');
            console.log(bill);

            this.form.user_id = bill.user_id;
            this.form.bill_id = bill.id;
            this.form.date = this.moment(bill.date).toDate();
            this.form.iva = bill.iva;
            this.form.isr_ret = bill.isr_ret;
            this.form.iva_ret = bill.iva_ret;
            this.form.subtotal = bill.subtotal;
            this.form.discount = bill.discount;
            this.form.ieps = bill.ieps;
            this.form.currency_id = bill.currency_id;
            // this.form.exchange = bill.exchange;
            
            this.form.exchange = bill.exchange_rate;
            this.form.change_date = bill.change_date;

            this.form.amount = bill.amount;
            // this.form.amount_payable = bill.amount;
            
            this.form.company_id = bill.company_id;
            this.form.description = bill.description;
            if (bill.single_purchase) {
                this.single_purchase = bill.single_purchase;
            }

            if (bill.credit) {
                this.form.credit = bill.credit.amount;
            }

            let cheksAmount = 0;
            if (bill.checks) {
                bill.checks.forEach(function (check) {
                    cheksAmount += (check.amount_payable * 1);
                });
            }


            // this.form.amount_payable =  parseFloat(this.form.subtotal) + parseFloat(this.form.iva) +  parseFloat(this.form.ieps) -  parseFloat(this.form.isr_ret) -  parseFloat(this.form.iva_ret) -  parseFloat(this.form.discount) -  parseFloat(this.form.credit);
            this.form.amount_payable = (this.form.subtotal * 1) + (this.form.iva * 1) + (this.form.ieps * 1) - (this.form.isr_ret * 1) - (this.form.iva_ret * 1) - (this.form.discount * 1) - (this.form.credit * 1) - (cheksAmount * 1);
            
            if(bill.pety_cash){
                this.form.type_id = 3;
                this.form.building_id = bill.pety_cash.building_id;
                this.form.company_id = bill.pety_cash.company_id;
                this.form.concept_id = 15;
            }


            this.$modal.show('addChecks');

        });
        Event.$on("event-show-addChecks", (account) => {
            // alert('paso 1');
            console.log(account);
            console.log(account);
            if (account.id) {
                this.form.id = account.id;
                this.form.type_id = account.type_id;
                this.form.user_id = account.user_id;
                this.form.concept_id = account.concept_id;
                this.form.budget_id = account.budget_id;
                this.form.buy_id = account.buy_id;
                this.form.bill_id = account.bill_id;
                this.form.amount = account.amount;
                this.form.amount_payable = account.amount_payable;
                this.form.type_payment_id = account.type_payment_id;
                this.form.description = account.description;
                this.form.currency_id = account.currency_id;
                this.form.exchange = account.exchange;
                this.form.change_date = account.change_date;



                this.form.subtotal = account.subtotal;
                this.form.iva = account.iva;
                this.form.isr_ret = account.isr_ret;
                this.form.iva_ret = account.iva_ret;
                this.form.discount = account.discount;
                this.form.ieps = account.ieps;
                this.form.credit = account.credit;

                if (account.buy) {
                    if (!account.buy.buy_id) {
                        this.single_purchase = account.single_purchase;
                    }
                }

                if (account.date) {
                    let dt = this.moment(account.date).toDate();
                    this.form.date = dt;
                }
                this.getBuysBudgets(this.form.user_id, this.form.concept_id);
                this.catalogue.get_bills_user(this.form.user_id, this.form.id);
            }
            this.$modal.show('addChecks');
        });
        Event.$on("event-load-budgets", async (budgets) => {
            if (this.form.user_id) {
                this.form.budget_id = budgets.id;
                await this.getDataBudget();
            }
        });
        Event.$on("event-load-buys", async (buys) => {
            if (this.form.user_id) {
                await this.getBuysBudgets(this.form.user_id);
                this.form.buy_id = buys.id;
                await this.getDataBuy();
            }
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
    watch: {
        'form.user_id'() {
            // this.form.budget_id = '';
            // this.form.buy_id = '';
            // this.form.bill_id = '';

            this.getBuysBudgets(this.form.user_id);
            this.catalogue.get_bills_user(this.form.user_id, this.form.id);
        },
        'form.type_id'() {
            // this.form.budget_id = '';
            // this.form.buy_id = '';

            this.getBuysBudgets(this.form.user_id);
        },
        'form.budget_id'() {
            this.getDataBudget();
        },
        'form.buy_id'() {
            this.getDataBuy();
        },
        async 'form.building_id'(value) {
            console.log(value);
            if (this.form.id == '') {
                console.log('cadcdascamdscdsk');

                await this.catalogue.get_departamens_buildings(value);
                await this.catalogue.get_companies_buildings(value);

                let stor = localStorage.getItem('bill_data');
                console.log(stor);

                if (stor) {
                    let obj = JSON.parse(stor);
                    console.log(obj);
                    this.form.company_id = obj.company_id;
                }
            }
        },
        'form.discount'() {
            this.calcTotal();
        },
        'form.subtotal'() {
            this.calcTotal();
        },
        'form.iva'() {
            this.calcTotal();
        },
        'form.isr_ret'() {
            this.calcTotal();
        },
        'form.iva_ret'() {
            this.calcTotal();
        },
        'form.ieps'() {
            this.calcTotal();
        },
        'form.credit'() {
            this.calcTotal();
        }

        // 'form.concept_id'() {
        //     // this.form.budget_id = '';
        //     // this.form.buy_id = '';

        //     this.getBuysBudgets(this.form.user_id, this.form.concept_id);
        // },
    }
}
</script>

<template>

    <modal name="addChecks" :width="'80%'" :scrollable="true" :maxHeight="300" :height="'auto'"
        style="z-index:2000 !important">


        <ModalFormComponentBudgets />
        <ModalFormComponentBuy />


        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información Cheque</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="mt-3" :class="single_purchase == 0 ? 'col-md-6' : 'col-md-12'">
                        <label>Tipo</label>
                        <select type="text" v-if="single_purchase == 0" :disabled="!prop_edit" class="form-control"
                            v-model="form.type_id">
                            <option :value="1">Presupuestos</option>
                            <option :value="2">Gastos recurrente</option>
                            <option :value="3" v-if="form.id == ''">Compra unica</option>
                        </select>
                        <p v-else style="font-size:20px;">
                            Compra unica
                        </p>

                        <div v-if="errors && errors.type_id" class="text-danger">{{ errors.type_id[0] }}</div>
                    </div>
                    <div class="mt-3" :class="single_purchase == 0 ? 'col-md-6' : 'col-md-8'">
                        <label>Usuario</label>
                        <select type="text" :disabled="!prop_edit || single_purchase != 0" class="form-control"
                            v-model="form.user_id">
                            <option v-for="(user, index_user) in catalogue.users_profile" :value="user.id"
                                :key="index_user">{{
        user.name }} {{ user.last_name }}</option>
                        </select>
                        <div v-if="errors && errors.user_id" class="text-danger">{{ errors.user_id[0] }}</div>
                    </div>

                    <div class="col-md-8 mt-3" v-if="form.type_id == 1">
                        <label>Presupuesto</label>
                        <div class="input-group mb-3 text-center" v-if="catalogue.budgets_user_load">
                            <p style="width: 100%;">
                                <i class="fa-solid fa-circle-notch fa-spin fa-lg"></i>
                            </p>
                        </div>
                        <div class="input-group mb-3" v-else>
                            <select type="text" :disabled="!prop_edit" required class="form-control"
                                v-model="form.budget_id">
                                <option v-for="(budget, index_budeget) in catalogue.budgets_user" :value="budget.id"
                                    :key="index_budeget">
                                    {{ budget.id }} -
                                    {{ budget.folio }},
                                    {{ budget.building.name }},
                                    $ {{ budget.amount }}
                                </option>
                                <option value="" v-if="catalogue.budgets_user.length == 0"> Sin resultados</option>
                            </select>
                            <button class="btn ml-3 btn-outline-success" type="button" @click="addBudget"> <i
                                    class="fa fa-plus"></i> </button>
                        </div>
                        <div class="text-sm text-info" v-if="form.user_id && catalogue.budgets_user.length == 0">Sin
                            presupuesto, necesita ingresar uno a este usuario</div>
                        <div v-if="errors && errors.budget_id" class="text-danger">{{ errors.budget_id[0] }}</div>
                    </div>

                    <div class="col-md-8 mt-3" v-if="form.type_id == 2 && single_purchase == 0">
                        <label>Gasto recurrente</label>
                        <div class="input-group mb-3 text-center" v-if="catalogue.buys_user_load">
                            <p style="width: 100%;">
                                <i class="fa-solid fa-circle-notch fa-spin fa-lg"></i>
                            </p>
                        </div>
                        <div class="input-group mb-3" v-else>
                            <select type="text" :disabled="!prop_edit" required class="form-control"
                                v-model="form.buy_id">
                                <option v-for="(buy, index_buy) in catalogue.buys_user" :value="buy.id"
                                    :key="index_buy">
                                    {{ buy.id }} -
                                    {{ buy.building.name }},
                                    {{ buy.payment_period.name }}
                                    {{ buy.amount }} - {{ buy.star_date | toDateTime }}
                                </option>
                                <option value="" v-if="catalogue.buys_user.length == 0"> Sin resultados</option>
                            </select>
                            <button class="btn ml-3 btn-outline-success" type="button" @click="addBuy"> <i
                                    class="fa fa-plus"></i> </button>
                        </div>
                        <div class="text-sm text-info" v-if="form.user_id && catalogue.buys_user.length == 0">Sin gasto
                            recurrente,
                            necesita ingresar uno a este usuario</div>
                        <div v-if="errors && errors.buy_id" class="text-danger">{{ errors.buy_id[0] }}</div>
                    </div>

                    <!-- Pago unico -->
                    <div class="col-md-6 mt-3" v-if="form.type_id == 3">
                        <div class="form-group">
                            <label> Propiedad </label>
                            <select type="text" class="form-control" v-model="form.building_id">
                                <option v-for="(building, index) in catalogue.buildings" :value="building.id"
                                    :key="index">
                                    {{ building.name }}</option>
                            </select>
                            <div v-if="errors && errors.building_id" class="text-danger">{{ errors.building_id[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3" v-if="form.type_id == 3">
                        <div class="form-group">
                            <label> Departamento </label>
                            <select v-model="form.indiviso_id" class="form-control">
                                <option value="">Sin departamento</option>
                                <option v-for="(departament, index) in catalogue.departamens_buildings" :key="index"
                                    :value="departament.id"> Número {{ departament.number }} / Piso {{ departament.level
                                    }}
                                </option>
                            </select>
                            <div v-if="errors && errors.indiviso_id" class="text-danger">{{ errors.indiviso_id[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-2" v-if="form.type_id == 3">
                        <div class="form-group">
                            <label> Empresa </label>
                            <select v-model="form.company_id" class="form-control">
                                <option value="">Sin empresa</option>
                                <option v-for="(company, index) in catalogue.companies_buildings" :key="index"
                                    :value="company.id"> {{ company.name }}
                                </option>
                            </select>
                            <div v-if="errors && errors.company_id" class="text-danger">{{ errors.company_id[0] }}
                            </div>
                        </div>
                    </div>
                    <!-- Pago unico -->

                    <div :class="form.type_id == 3 ? 'col-md-6 mt-2' : 'col-md-4 mt-3'">
                        <label>Categoría</label>
                        <select type="text" :disabled="form.type_id != 3" class="form-control"
                            v-model="form.concept_id">
                            <option v-for="(concept, index_concept) in catalogue.concepts" :value="concept.id"
                                :key="index_concept">{{
        concept.name }}</option>
                        </select>
                        <div v-if="errors && errors.concept_id" class="text-danger">{{ errors.concept_id[0] }}</div>
                    </div>
                    <div :class="form.currency_id == 2 ? 'col-md-4 mt-2' : 'col-md-12 mt-2'">
                        <label>Concepto</label>
                        <select type="text" :disabled="!prop_edit || single_purchase != 0" class="form-control"
                            v-model="form.bill_id">
                            <option v-if="form.id == ''" value="">Crear Factura</option>
                            <option v-for="(bill, index_bill) in catalogue.bills_user" :value="bill.id"
                                :key="index_bill">{{
        bill.name }}</option>
                            <option value="" v-if="catalogue.bills_user.length == 0 && form.id != ''"> Sin resultados
                            </option>
                        </select>
                        <span v-if="form.id == ''" class="text-info text-sm">DEJE EN BLANCO PARA QUE EL USUARIOS
                            POSTERIORMENTE SUBA SU FACTURA</span>
                        <div v-if="errors && errors.bill_id" class="text-danger">{{ errors.bill_id[0] }}</div>
                    </div>

                    <div v-if="form.currency_id == 2" class="col-md-4 mt-2 ">
                        <label>Tipo de cambio</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.exchange"></money>
                        <div v-if="errors && errors.exchange" class="text-danger">{{ errors.exchange[0] }}</div>
                    </div>

                    <div class="col-md-4 mt-2" v-if="form.currency_id == 2">
                        <label> Fecha tipo de cambio </label>
                        <date-picker v-model="form.change_date" :disabled="!prop_edit"
                            valueType="format"></date-picker>
                        <div v-if="errors && errors.change_date" class="text-danger">{{ errors.change_date[0] }}</div>
                    </div>
                    <!--  -->

                    <div class="col-md-3 mt-3">
                        <label>Subtotal</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.subtotal"></money>
                        <div v-if="errors && errors.subtotal" class="text-danger">{{ errors.subtotal[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>IVA</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.iva"></money>
                        <div v-if="errors && errors.iva" class="text-danger">{{ errors.iva[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>IEPS</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.ieps"></money>
                        <div v-if="errors && errors.ieps" class="text-danger">{{ errors.ieps[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>ISR RET</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.isr_ret"></money>
                        <div v-if="errors && errors.isr_ret" class="text-danger">{{ errors.isr_ret[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>IVA RET</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.iva_ret"></money>
                        <div v-if="errors && errors.iva_ret" class="text-danger">{{ errors.iva_ret[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>Descuento</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.discount"></money>
                        <div v-if="errors && errors.discount" class="text-danger">{{ errors.discount[0] }}</div>
                    </div>


                    <!--  -->

                    <div class="col-md-6 mt-3">
                        <label>Total</label>
                        <money readonly :disabled="!prop_edit" class="form-control" v-model="form.amount"></money>
                        <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}</div>
                    </div>


                    <div class="col-md-4 mt-3">
                        <label>Monto a pagar</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.amount_payable"></money>
                        <div v-if="errors && errors.amount_payable" class="text-danger">{{ errors.amount_payable[0] }}
                        </div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <label>Fecha</label><br>
                        <date-picker format="YYYY-MM-DD" :disabled="!prop_edit" v-model="form.date"></date-picker>
                        <div v-if="errors && errors.date" class="text-danger">{{ errors.date[0] }}</div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <label>Tipo de saldo</label>
                        <select type="text" :disabled="!prop_edit" class="form-control" v-model="form.type_payment_id">
                            <option v-for="(type_payment, index_type_payment) in catalogue.type_payments"
                                :value="type_payment.id" :key="index_type_payment">{{
        type_payment.name }}</option>
                        </select>
                        <div v-if="errors && errors.type_payment_id" class="text-danger">{{ errors.type_payment_id[0] }}
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <label>Descripción</label>
                        <textarea type="text" :disabled="!prop_edit" class="form-control"
                            v-model="form.description"></textarea>
                        <div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Archivo</label><br>
                        <div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" :disabled="!prop_edit" class="custom-file-input"
                                :class="file != '' ? 'pr-5' : ''" id="customFile" ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>

                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>