<script>
import { mapGetters } from 'vuex';

import ModalFormComponent from '@/components/departments/ModalFormComponent.vue';
import ModalUserDepartament from './ModalUserDepartamentComponent.vue';
import ModalListPaysServices from './ModalListPaysServicesComponent.vue';
import ModalContractDepartament from './ModalContractDepartamentComponent.vue';
import ModalContract from '@/components/contracts/ModalTableContractsComponent.vue';
import Catalogue from '@/services/catalogue';

export default {
    name: "departaments-table",
    components: {
        ModalFormComponent,
        ModalUserDepartament,
        ModalListPaysServices,
        ModalContractDepartament,
        ModalContract
    },
    data() {
        return {
            departaments: {},
            search: '',
            building_id: '',
            catalogue: new Catalogue(),
        }
    },
    methods: {
        formaContract(info) {
            Event.$emit('event-show-addContract', info);
        },
        formDepartament(info) {
            Event.$emit('event-show-addDepartament', info);
        },
        formPayServicesDepartament(department) {
            Event.$emit('event-load-pays-services', department);
        },
        formDepartamentUser(departament, tenant) {
            Event.$emit('event-show-addDepartamentUser', departament, tenant);
        },
        showContract(departament) {
            Event.$emit('event-show-ContractsDepartament', departament);
        },
        editTenant(buy = {}, edit = false) {
            this.editForm = edit;
            Event.$emit('event-show-addDepartamentUser', buy);
        },
    },
    mounted() {
        let id = this.$route.params.id;
        if (id) {
            this.building_id = id;
            this.catalogue.get_departamens_buildings(this.building_id);
        }
    },
    created() {
        Event.$on("event-load-departaments", () => {
            this.catalogue.get_departamens_buildings(this.building_id);
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <h5>Lista Departamentos</h5>
            <button @click="formDepartament()" v-can="'add_bill'" class="btn btn-sm btn-outline-success float-right"
                style="margin-top: -35px;">
                <i class="fa fa-plus"></i> Departamento
            </button>
            <button @click="formDepartament()" v-if="authUser.id == 1421 || authUser.id == 26" class="btn btn-sm btn-outline-success float-right"
                style="margin-top: -35px;">
                <i class="fa fa-plus"></i> Departamento
            </button>
            <hr>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-md-6">
                    <label>Buscar :</label>
                    <input type="text" class="form-control" v-model="search" @keyup="list()" />
                </div>
            </div>
            <br>
            <div class="table-responsive">
                <table class="table table-bordered text-center text-sm">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Número</th>
                            <th>Piso</th>
                            <th>Metros cuadrados</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="catalogue.departamens_buildings">
                        <tr v-for="(departament, index) in catalogue.departamens_buildings" :key="index">
                            <td>{{ departament.id }}</td>
                            <td>
                                <button v-if="departament.current_tenant" @click="editTenant(departament.current_tenant, false)" class="btn btn-sm btn-outline-success mr-2">
                                   <i class="fa-solid fa-file"></i>
                                </button>

                                <button v-if="departament.tenants_concurrent.length > 0" @click="editTenant(departament.tenants_concurrent[0], false)" class="btn btn-sm btn-outline-success mr-2">
                                   <i class="fa-solid fa-file"></i>
                                </button>
                                {{ departament.number }}
                            
                            </td>
                            <td>{{ departament.level }}</td>
                            <td>{{ departament.square_meter }}</td>
                            <td>
                                <!-- <button @click="formaContract(departament)"
                                    v-tooltip.bottom-start="'Tooltip on bottom'"
                                    class="btn m-1 btn-outline-success btn-sm">
                                    <i class="fa-solid fa-file-signature"></i> Contrato
                                </button> -->
                                <button @click="showContract(departament)"
                                    v-tooltip.bottom-start="'Tooltip on bottom'"
                                    class="btn m-1 btn-outline-secondary btn-sm">
                                    <i class="fas fa-history "> </i> Historial de renta
                                </button>

                                <button @click="formDepartament(departament)"
                                    v-tooltip.bottom-start="'Tooltip on bottom'"
                                    class="btn m-1 btn-outline-primary btn-sm">
                                    <i class="fas fa-edit "> </i> Editar
                                </button>
                                <!-- <button @click="formPayServicesDepartament(departament)"
                                    v-tooltip.bottom-start="'Tooltip on bottom'"
                                    class="btn m-1 btn-outline-warning btn-sm">
                                    <i class="fas fa-hand-holding-dollar"> </i> Pagar servicios
                                </button> -->
                                <button type="button" @click="deleted(departament.id)"
                                    v-tooltip.bottom-start="'Tooltip on bottom'"
                                    class="btn m-1 btn-outline-danger btn btn-sm ">
                                    <i class="fa fa-trash"></i> Eliminar
                                </button>

                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td align="center" colspan="5">Sin resultados.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ModalFormComponent />
        <ModalUserDepartament :prop_edit="false" />
        <ModalListPaysServices />
        <ModalContractDepartament />
        <ModalContract />
    </div>
</template>